import React from 'react';
import Plot from 'react-plotly.js';

const CorrelationHeatmap = ({ data }) => {
  const labels = Object.keys(data);
  const values = labels.map(label => labels.map(l => data[label][l]));

  return (
    <Plot
      data={[
        {
          z: values,
          x: labels,
          y: labels,
          type: 'heatmap',
          colorscale: 'Viridis'
        }
      ]}
      layout={{
        width: 600,
        height: 600,
        title: 'Feature Correlation Heatmap'
      }}
    />
  );
};

export default CorrelationHeatmap;