import React from 'react';
import { MapContainer, TileLayer, GeoJSON } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

const GeospatialHeatmap = ({ data }) => {
  const getColor = (score) => {
    return score > 0.8 ? '#800026' :
           score > 0.6 ? '#BD0026' :
           score > 0.4 ? '#E31A1C' :
           score > 0.2 ? '#FC4E2A' :
                         '#FFEDA0';
  };

  const style = (feature) => {
    return {
      fillColor: getColor(feature.properties.stanley_score),
      weight: 2,
      opacity: 1,
      color: 'white',
      dashArray: '3',
      fillOpacity: 0.7
    };
  };

  return (
    <MapContainer center={[47.6062, -122.3321]} zoom={10} style={{ height: '400px', width: '100%' }}>
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      <GeoJSON data={data} style={style} />
    </MapContainer>
  );
};

export default GeospatialHeatmap;