import React, { useState, useEffect } from 'react';
import GeospatialHeatmap from './GeospatialHeatmap';
import CorrelationHeatmap from './CorrelationHeatmap';
import DataTable from './DataTable';
import { fetchData, fetchSpatialData, fetchCorrelationData } from '../utils/api';

const Dashboard = () => {
  const [data, setData] = useState([]);
  const [spatialData, setSpatialData] = useState(null);
  const [correlationData, setCorrelationData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadData = async () => {
      try {
        const [result, spatial, correlation] = await Promise.all([
          fetchData(),
          fetchSpatialData(),
          fetchCorrelationData()
        ]);
        setData(result);
        setSpatialData(spatial);
        setCorrelationData(correlation);
      } catch (err) {
        console.error("Failed to fetch data:", err);
        setError("Failed to load data. Please try again later.");
      } finally {
        setLoading(false);
      }
    };
    loadData();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="dashboard">
      <h1>Ridwell Data Analysis Dashboard</h1>
      {spatialData && <GeospatialHeatmap data={spatialData} />}
      {correlationData && <CorrelationHeatmap data={correlationData} />}
      <DataTable data={data} />
    </div>
  );
};

export default Dashboard;