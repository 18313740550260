export const fetchData = async () => {
  const response = await fetch('https://ridwell.mybwd.com/api/data');
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  return response.json();
};

export const fetchSpatialData = async () => {
  const response = await fetch('https://ridwell.mybwd.com/api/spatial_data');
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  return response.json();
};

export const fetchCorrelationData = async () => {
  const response = await fetch('https://ridwell.mybwd.com/api/correlation_analysis');
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  return response.json();
};